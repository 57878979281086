
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import Plotly, { Data } from 'plotly.js-finance-dist-min';
import { Empty } from 'ant-design-vue';

interface COWdata {
  country: string;
  dataExp: number;
  dataNat: number;
  unspecified: number;
}

export default defineComponent({
  name: 'CountryOfWorkGraph',
  components: {
    Empty,
  },
  data() {
    return {
      dataExists: true,
    };
  },
  props: {
    filter: {
      type: String,
      default: 'Alphabetical Asc',
    },
  },
  mounted() {
    this.graph();
  },
  computed: {
    ...mapGetters('training', ['getFilterValues', 'getLoadingState']),
  },
  methods: {
    graph() {
      const t = this.renderCOW();
      // @ts-expect-error values not found in Data plotly issue
      this.dataExists = t.some((trace) => (trace.y.some((k) => (k !== 0))));
      this.$nextTick(() => {
        if (this.dataExists) {
          const { width, height } = (this.$refs.CountryOfWorkChart as Element).getBoundingClientRect();
          let domain = [0, 1];
          // @ts-expect-error values not found in Data plotly issue
          if (t[0].x.length <= 20) domain = [0, 0.65];
          // @ts-expect-error values not found in Data plotly issue
          if (t[0].x.length <= 10) domain = [0, 0.45];
          // @ts-expect-error values not found in Data plotly issue
          if (t[0].x.length <= 5) domain = [0, 0.35];
          // @ts-expect-error values not found in Data plotly issue
          if (t[0].x.length === 1) domain = [0, 0.25];
          const layout = {
            width: 2000,
            height,
            xaxis: {
              constraintoward: 'left',
              showgrid: false,
              linecolor: '#e2e2e2',
              tickfont: {
                color: 'rgba(54, 53, 52, 0.74)',
                family: 'DINNextLTPro-Regular, Arial',
                size: 12,
              },
              tickangle: -45,
              domain,
            },
            yaxis: {
              tickfont: {
                color: 'rgba(54, 53, 52, 0.74)',
                family: 'DINNextLTPro-Regular, Arial',
              },
              title: {
                text: 'Number of Trainees',
                font: {
                  family: 'DINNextLTPro-Medium, Arial',
                  size: 12,
                  color: 'rgba(54, 53, 52, 0.74)',
                },
              },
            },
            domain: [0, 0.5],
            legend: {
              xanchor: 'center',
              yanchor: 'top',
              font: {
                family: 'DINNextLTPro-Medium, Arial',
                size: 12,
              },
              y: -0.4,
              x: 0.25,
              orientation: 'h',
            },
            margin: {
              t: 0,
              r: 20,
              b: 150,
            },
            barmode: 'stack',
          };
          // @ts-expect-error enum error expected
          Plotly.react('COW', t, layout);
        }
      });
    },
    applyFilter(data: COWdata[]): COWdata[] {
      const f = this.$props.filter;
      if (f.includes('Alphabetical')) {
        const dir = (f.split(' '))[1];
        if (dir === 'Asc') {
          data.sort((a, b) => (a.country > b.country ? 1 : -1));
        } else {
          data.sort((a, b) => (a.country < b.country ? 1 : -1));
        }
      } else if (f.includes('Staff')) {
        const dir = (f.split(' '))[1];
        if (dir === 'Asc') {
          data.sort((a, b) => (a.unspecified + a.dataExp + a.dataNat > b.unspecified + b.dataExp + b.dataNat ? 1 : -1));
        } else {
          data.sort((a, b) => (a.unspecified + a.dataExp + a.dataNat < b.unspecified + b.dataExp + b.dataNat ? 1 : -1));
        }
      }
      return data;
    },
    renderCOW():Data[] {
      let allData:COWdata[] = [];
      const values = this.$store.getters['training/getFilterValues'];
      if (values && values.length > 0) {
        const index = values[0].indexOf('Trainee Country of Work/Work Base');
        const staffIndex = values[0].indexOf('Local Capacity');
        values.forEach((v:string[], i:number) => {
          if (i !== 0 && index <= v.length - 1 && staffIndex <= v.length - 1 && v[index]) {
            const c = allData.findIndex((o: COWdata) => o.country === v[index]);
            if (c === -1) {
              allData.push({
                country: v[index],
                dataExp: 0,
                dataNat: 0,
                unspecified: 0,
              });
              if (v[staffIndex].includes('National')) {
                allData[allData.length - 1].dataNat += 1;
              } else if (v[staffIndex].includes('Expatriate')) {
                allData[allData.length - 1].dataExp += 1;
              } else {
                allData[allData.length - 1].unspecified += 1;
              }
            } else if (v[staffIndex].includes('National')) {
              allData[c].dataNat += 1;
            } else if (v[staffIndex].includes('Expatriate')) {
              allData[c].dataExp += 1;
            } else if (allData[c].country) {
              allData[c].unspecified += 1;
            }
          }
        });
      }
      allData = this.applyFilter(allData);
      const countries = allData.map((x) => x.country);
      const dataExp = allData.map((x) => x.dataExp);
      const dataNat = allData.map((x) => x.dataNat);
      const unspecified = allData.map((x) => x.unspecified);
      let colorBarWidth = (0.00657 * countries.length) + 0.00561;
      let borderBarWidth = (0.005895 * countries.length) + 0.005286;
      if (countries.length <= 10) {
        colorBarWidth += 0.02;
        borderBarWidth += 0.02;
      }
      const traces:Data = {
        x: countries,
        y: dataExp,
        textfont: {
          family: 'DINNextLTPro-Regular',
        },
        hovertemplate: ' <b style="color: #a590f0; fontSize: 20px;">&#10687;</b>  <span><b>Expatriates</b>  (%{x}, n=%{y})</span><extra></extra>',
        hoverlabel: {
          bgcolor: '#363534',
          bordercolor: '#363534',
          font: {
            color: '#FFFFFF',
            family: 'DINNextLTPro',
            size: 12.5,
          },
        },
        width: colorBarWidth,
        type: 'bar',
        marker: {
          color: '#a590f0',
        },
        name: 'Expatriates',
      };
      const tracesTwo:Data = {
        x: countries,
        y: dataNat,
        textfont: {
          family: 'DINNextLTPro-Regular',
        },
        hovertemplate: ' <b style="color: #705cc1; fontSize: 20px;">&#10687;</b>  <span><b>National Staff</b>  (%{x}, n=%{y})</span><extra></extra>',
        hoverlabel: {
          bgcolor: '#363534',
          bordercolor: '#363534',
          font: {
            color: '#FFFFFF',
            family: 'DINNextLTPro',
            size: 12.5,
          },
        },
        width: colorBarWidth,
        type: 'bar',
        marker: {
          color: '#705cc1',
        },
        name: 'National Staff',
      };
      const tracesThree:Data = {
        x: countries,
        y: unspecified,
        textfont: {
          family: 'DINNextLTPro-Regular',
        },
        hovertemplate: ' <b style="color: #fffff; fontSize: 20px;">&#10687;</b>  <span><b>Unspecified</b>  (%{x}, n=%{y})</span><extra></extra>',
        hoverlabel: {
          bgcolor: '#363534',
          bordercolor: '#363534',
          font: {
            color: '#FFFFFF',
            family: 'DINNextLTPro',
            size: 12.5,
          },
        },
        type: 'bar',
        width: borderBarWidth,
        marker: {
          color: '#FFFFFF',
          line: {
            color: '#7e69ca',
            width: 2,
          },
          opacity: 0.5,
        },
        name: 'Unspecified',
      };

      return [traces, tracesTwo, tracesThree];
    },
  },
  watch: {
    getLoadingState(newProps, oldProps) {
      if (oldProps === 'loading' && newProps === 'done') {
        this.graph();
      }
    },
    filter() {
      this.graph();
    },
  },
});
