
import { defineComponent, PropType } from 'vue';
import Plotly, { Data } from 'plotly.js-finance-dist-min';
import { Empty } from 'ant-design-vue';

interface TBYprops {
  year: number;
  countrySpecific: number;
  regional: number;
  virtual: number;
  unspecified: number;
}

export default defineComponent({
  name: 'TrainingByYearGraph',
  components: {
    Empty,
  },
  props: {
    values: {
      type: Array as PropType<Array<string[]>>,
      default: [] as Array<string[]>,
    },
    getLoadingState: {
      type: String,
    },
    getTrainings: {
      type: Array as PropType<Array<string[]>>,
      default: [] as Array<string[]>,
    },
  },
  data() {
    return {
      dataExists: true,
    };
  },
  mounted() {
    this.graph();
  },
  methods: {
    graph():void {
      const t = this.renderTBY();
      // @ts-expect-error values not found in Data plotly issue
      this.dataExists = t.some((trace) => (trace.y.some((k) => (k !== 0))));
      this.$nextTick(() => {
        if (this.dataExists) {
          const { width, height } = (this.$refs.TrainingByYearChart as Element).getBoundingClientRect();
          const layout = {
            width,
            height,
            xaxis: {
              dtick: 1,
              showgrid: false,
              linecolor: '#e2e2e2',
              tickfont: {
                color: 'rgba(54, 53, 52, 0.74)',
                family: 'DINNextLTPro-Regular, Arial',
                size: 12,
              },
            },
            yaxis: {
              tickfont: {
                color: 'rgba(54, 53, 52, 0.74)',
                family: 'DINNextLTPro-Regular, Arial',
              },
              rangemode: 'tozero',
              title: {
                text: 'Number of Trainings',
                font: {
                  family: 'DINNextLTPro-Medium, Arial',
                  size: 12,
                  color: 'rgba(54, 53, 52, 0.74)',
                },
              },
            },
            legend: {
              xanchor: 'center',
              yanchor: 'top',
              font: {
                family: 'DINNextLTPro-Medium, Arial',
                size: 14,
              },
              y: -0.2,
              x: 0.5,
              orientation: 'h',
              margin: {
                l: 0,
                r: 0,
              },
            },
            margin: {
              t: 20,
              l: 35,
              r: 20,
              b: 0,
            },
          };
          // @ts-expect-error enum error expected
          Plotly.react('TBY', t, layout, { responsive: true, displayModeBar: false });
        }
      });
    },
    renderTBY():Data[] {
      let tbyData: TBYprops[] = [];

      const { values, getTrainings } = this;
      if (values && values.length > 0) {
        const index = values[0].indexOf('Year of Training');
        const LevelIndex = values[0].indexOf('Level of Event');
        getTrainings.forEach((v:string[]) => {
          if (index <= v.length - 1 && LevelIndex <= v.length - 1) {
            const c = tbyData.findIndex((o: TBYprops) => o.year === parseInt(v[index], 10));
            if (c === -1 && v[index]) {
              tbyData.push({
                year: parseInt(v[index], 10),
                countrySpecific: 0,
                regional: 0,
                virtual: 0,
                unspecified: 0,
              });
              if (v[LevelIndex] && v[LevelIndex].includes('Regional')) {
                tbyData[tbyData.length - 1].regional += 1;
              } else if (v[LevelIndex] && v[LevelIndex].includes('Country')) {
                tbyData[tbyData.length - 1].countrySpecific += 1;
              } else if (v[LevelIndex] && v[LevelIndex].includes('Virtual')) {
                tbyData[tbyData.length - 1].virtual += 1;
              } else {
                tbyData[tbyData.length - 1].unspecified += 1;
              }
            } else if (v[LevelIndex] && v[LevelIndex].includes('Regional')) {
              tbyData[c].regional += 1;
            } else if (v[LevelIndex] && v[LevelIndex].includes('Country')) {
              tbyData[c].countrySpecific += 1;
            } else if (v[LevelIndex] && v[LevelIndex].includes('Virtual')) {
              tbyData[c].virtual += 1;
            } else {
              tbyData[c].unspecified += 1;
            }
          }
        });
      }
      tbyData = tbyData.filter((x) => (x.countrySpecific + x.regional + x.virtual + x.unspecified !== 0));
      tbyData = tbyData.sort((a, b) => (a.year - b.year));
      const tbyYears = tbyData.map((x) => x.year);
      let years = tbyYears;
      if (tbyYears.length >= 2) {
        years = [tbyYears[0]];
        let i = 0;
        while (tbyYears && tbyYears.length >= 2 && years[years.length - 1] !== tbyYears[tbyYears.length - 1]) {
          if (tbyData[i].year !== years[years.length - 1]) {
            tbyData.splice(i, 0, {
              year: years[years.length - 1],
              countrySpecific: 0,
              regional: 0,
              virtual: 0,
              unspecified: 0,
            });
          } else {
            years.push(years[years.length - 1] + 1);
            i += 1;
          }
        }
      }
      const regional = tbyData.map((x) => (x.regional ? x.regional : 0));
      const virtual = tbyData.map((x) => (x.virtual ? x.virtual : 0));
      const countrySpecific = tbyData.map((x) => (x.countrySpecific ? x.countrySpecific : 0));
      const allSmart = tbyData.map((x) => (x.countrySpecific + x.regional + x.virtual + x.unspecified));
      const traces:Data = {
        x: years,
        y: [0, 0, 0, 0, 0, 0, 0, 0],
        textfont: {
          family: 'DINNextLTPro-Regular',
        },
        type: 'scatter',
        name: 'All',
        visible: 'legendonly',
        marker: {
          color: '#f3c912',
          symbol: 'circle',
        },
      };
      const tracesTwo:Data = {
        x: years,
        y: allSmart,
        textfont: {
          family: 'DINNextLTPro-Regular',
        },
        type: 'scatter',
        name: 'All Smart',
        marker: {
          color: '#48adb6',
          symbol: 'circle',
        },
        connectgaps: true,
        hovertemplate: ' <b style="color: #48adb6; fontSize: 20px;">&#10687;</b>  <span><b>All Smart</b>  (%{x}, n=%{y})</span><extra></extra>',
        hoverlabel: {
          bgcolor: '#363534',
          bordercolor: '#363534',
          font: {
            color: '#FFFFFF',
            family: 'DINNextLTPro',
            size: 12.5,
          },
        },
      };
      const tracesThree:Data = {
        x: years,
        y: regional,
        textfont: {
          family: 'DINNextLTPro-Regular',
        },
        type: 'scatter',
        name: 'SMART Regional',
        marker: {
          color: '#e98300',
          symbol: 'circle',
        },
        connectgaps: true,
        hovertemplate: ' <b style="color: #e98300; fontSize: 20px;">&#10687;</b>  <span><b>SMART Regional</b>  (%{x}, n=%{y})</span><extra></extra>',
        hoverlabel: {
          bgcolor: '#363534',
          bordercolor: '#363534',
          font: {
            color: '#FFFFFF',
            family: 'DINNextLTPro',
            size: 12.5,
          },
        },
      };
      const tracesFour:Data = {
        x: years,
        y: virtual,
        textfont: {
          family: 'DINNextLTPro-Regular',
        },
        type: 'scatter',
        name: 'Virtual',
        marker: {
          color: '#9BC325',
          symbol: 'circle',
        },
        connectgaps: true,
        hovertemplate: ' <b style="color: #614fa3; fontSize: 20px;">&#10687;</b>  <span><b>Virtual</b>  (%{x}, n=%{y})</span><extra></extra>',
        hoverlabel: {
          bgcolor: '#363534',
          bordercolor: '#363534',
          font: {
            color: '#FFFFFF',
            family: 'DINNextLTPro',
            size: 12.5,
          },
        },
      };
      const tracesFive:Data = {
        x: years,
        y: countrySpecific,
        textfont: {
          family: 'DINNextLTPro-Regular',
        },
        type: 'scatter',
        name: 'SMART Country-Specific',
        marker: {
          color: '#ea4e4e',
          symbol: 'circle',
        },
        connectgaps: true,
        hovertemplate: ' <b style="color: #ea4e4e; fontSize: 20px;">&#10687;</b>  <span><b>SMART Country-Specific</b>  (%{x}, n=%{y})</span><extra></extra>',
        hoverlabel: {
          bgcolor: '#363534',
          bordercolor: '#363534',
          font: {
            color: '#FFFFFF',
            family: 'DINNextLTPro',
            size: 12.5,
          },
        },
      };
      const tracesSix:Data = {
        x: years,
        y: new Array(years.length).fill(0),
        textfont: {
          family: 'DINNextLTPro-Regular',
        },
        type: 'scatter',
        name: 'SENS',
        visible: 'legendonly',
        marker: {
          color: '#614fa3',
          symbol: 'circle',
        },
      };

      return [traces, tracesTwo, tracesThree, tracesFour, tracesFive, tracesSix];
    },
  },
  watch: {
    getLoadingState(newProps, oldProps) {
      if (oldProps === 'loading' && newProps === 'done') {
        this.graph();
      }
    },
  },
});
