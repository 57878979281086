
import { defineComponent } from 'vue';
import TrainingByYearGraph from './TrainingByYear.vue';

export default defineComponent({
  name: 'TrainingByYear',
  components: {
    TrainingByYearGraph,
  },
  props: {
    module: {
      type: String,
      default: 'training',
    },
  },
  computed: {
    getTrainings() {
      let x = [];
      if (this.module === 'training') {
        x = this.$store.getters['training/getTrainings'];
      } else if (this.module === 'overviewFilter') {
        x = this.$store.getters['overviewFilter/getTrainings'];
      }
      return x;
    },
    getValues() {
      let x = [];
      if (this.module === 'training') {
        x = this.$store.getters['training/getFilterValues'];
      } else if (this.module === 'overviewFilter') {
        x = this.$store.getters['overviewFilter/getTrainingData'];
      }
      return x;
    },
    getLoadingState() {
      let x = 'inactive';
      if (this.module === 'training') {
        x = this.$store.getters['training/getLoadingState'];
      } else if (this.module === 'overviewFilter') {
        x = this.$store.getters['overviewFilter/getLoading'];
      }
      return x;
    },
  },
});
