
import { defineComponent } from 'vue';
import { CaretDownFilled } from '@ant-design/icons-vue';
import { Select } from 'ant-design-vue';
import CountryOfWorkGraph from './CountryOfWork.vue';

const { Option } = Select;

export default defineComponent({
  name: 'CountryOfWork',
  components: {
    CountryOfWorkGraph,
    Select,
    Option,
    CaretDownFilled,
  },
  data() {
    return {
      value: 'Staff Desc',
    };
  },
});
