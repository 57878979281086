import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "training-graphs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountryOfWork = _resolveComponent("CountryOfWork")!
  const _component_TrainingByYear = _resolveComponent("TrainingByYear")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CountryOfWork),
    _createVNode(_component_TrainingByYear, { module: "training" })
  ]))
}