
import { defineComponent } from 'vue';
import CountryOfWork from './CountryOfWork/index.vue';
import TrainingByYear from './TrainingByYear/index.vue';

export default defineComponent({
  name: 'Graphs',
  components: {
    CountryOfWork,
    TrainingByYear,
  },
});
