import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "COW",
  ref: "CountryOfWorkChart"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Empty = _resolveComponent("Empty")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['cow-graph-container', _ctx.dataExists ? '' : 'justify-center'])
  }, [
    (_ctx.dataExists)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, null, 512))
      : (_openBlock(), _createBlock(_component_Empty, {
          key: 1,
          description: "No data to visualize"
        }))
  ], 2))
}